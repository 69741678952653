import React, {useEffect, useState} from 'react'
import {PropTypes} from 'prop-types'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import {Transition} from '@headlessui/react'

export const TRANSITION_CLASS = {
  EASE: 'tw-duration-300 tw-ease-out',
  OPEN: 'tw-max-h-screen tw-opacity-100',
  CLOSE: 'tw-max-h-0 tw-opacity-0',
  OPENED: '!tw-max-h-fit',
}

const Accordion = ({
  as: Component = 'section',
  id,
  title,
  children,
  show = true,
  headerOptions,
  hasErrors = false,
}) => {
  const [isOpen, setIsOpen] = useState(show || hasErrors)

  useEffect(() => {
    if (hasErrors) {
      setIsOpen(true)
    }
  }, [hasErrors])

  const handleToggle = (event) => {
    event.preventDefault()
    setIsOpen((prevState) => !prevState)
  }

  return (
    <Component className="card tw-mb-6 tw-overflow-visible">
      <AccordionHeader {...{id, title, handleToggle, isOpen, ...headerOptions}} />
      <Transition
        show={isOpen}
        unmount={false}
        className={TRANSITION_CLASS.EASE}
        enterFrom={TRANSITION_CLASS.CLOSE}
        enterTo={TRANSITION_CLASS.OPEN}
        entered={TRANSITION_CLASS.OPENED}
        leaveFrom={TRANSITION_CLASS.OPEN}
        leaveTo={TRANSITION_CLASS.CLOSE}
      >
        <div className="tw-p-4 md:tw-px-8">{children}</div>
      </Transition>
    </Component>
  )
}

const AccordionHeader = ({id, title, component, onAdd, onRemove, state, handleToggle, isOpen}) => {
  const HeaderComponent = component

  return (
    <div
      className="tw-py-2 tw-px-4"
      id={`heading${id}`}
      role="group"
      aria-labelledby={`heading${id}-label`}
    >
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-x-4 md:tw-items-center">
        <div>
          <a
            href="#"
            target="_self"
            className="tw-text-xl tw-select-none"
            onClick={handleToggle}
            aria-expanded={isOpen}
          >
            <h2 className="h4 tw-inline !tw-font-bold" id={`heading${id}-label`}>
              {title}
            </h2>
            <FontAwesomeIcon
              icon={faAngleRight}
              className={classNames('tw-ml-1.5 tw-w-4 tw-text-brand-icon_text tw-duration-300', {
                'tw-rotate-90': isOpen,
              })}
            />
          </a>
        </div>
        <div>
          {component ? <HeaderComponent onAdd={onAdd} onRemove={onRemove} state={state} /> : null}
        </div>
      </div>
    </div>
  )
}

AccordionHeader.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  handleToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  state: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ]),
}

Accordion.propTypes = {
  as: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool,
  hasErrors: PropTypes.bool,
  headerOptions: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    state: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ]),
  }),
}

export default Accordion
