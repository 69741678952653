import React, {
  ElementType,
  useState,
  useEffect,
  useRef,
  ReactNode,
  MutableRefObject,
  MouseEvent,
} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {usePopper} from 'react-popper'
import {Placement, Modifier} from '@popperjs/core'
import classNames from 'classnames'

export interface IPopoverHover {
  children: ReactNode
  content: ReactNode & {
    props?: {
      id?: string
    }
  }
  options?: object
  className?: string
  buttonElement?: ElementType
  buttonClassName?: string
  panelClassName?: string
  delay?: {
    enter?: number
    leave?: number
  }
}

interface PopoverRenderPropArg {
  open: boolean
  close(
    focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | MouseEvent<HTMLElement>
  ): void
}

const defaultOptions: {
  placement: Placement
  // Original definiton uses "any"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modifiers: Array<Partial<Modifier<any, any>>>
} = {
  placement: 'top',
  modifiers: [],
}

const PopoverHover = ({
  children,
  content,
  options = {},
  className = 'tw-inline-block',
  buttonElement = 'button',
  buttonClassName = 'tw-cursor-pointer tw-outline-none',
  panelClassName = 'tw-w-72 tw-text-sm',
  delay,
  ...props
}: IPopoverHover) => {
  let timeout: ReturnType<typeof setTimeout>
  const spanEventRef = useRef<boolean>(false)
  const referenceElement = useRef<HTMLButtonElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const combinedOptions = {
    ...defaultOptions,
    ...options,
  }
  const combinedDelay = {
    enter: 0,
    leave: 0,
    ...delay,
  }

  const {styles, attributes} = usePopper(referenceElement.current, popperElement, combinedOptions)

  const handlePopoverEnter = ({open}: PopoverRenderPropArg, isTouchEvent: boolean) => {
    clearTimeout(timeout)

    if (open || spanEventRef.current || isTouchEvent) {
      spanEventRef.current = isTouchEvent

      return
    }

    return referenceElement.current?.click()
  }

  const handlePopoverLeave = ({open, close}: PopoverRenderPropArg) => {
    if (!open) {
      return
    }

    timeout = setTimeout(() => {
      if (open) {
        spanEventRef.current = false
        close()
      }
    }, combinedDelay.leave)
  }

  useEffect(() => {
    return () => clearTimeout(timeout)
  }, [])

  const buttonProps: {
    'aria-describedby'?: string
    type?: 'button'
  } = {}

  /**
   * If the "content" JSX element/object contains an "id" property,
   * for example <p id="unique-identifier">Tooltip Text</p>
   * Use that ID for the "aria-describedby" accessibility property on the button element.
   */
  if (content?.props?.id) {
    buttonProps['aria-describedby'] = content.props.id
  }

  if (buttonElement === 'button') {
    buttonProps.type = 'button'
  }

  return (
    <Popover {...{className: classNames(className), ...props}}>
      {(props) => {
        return (
          <span
            onTouchStart={handlePopoverEnter.bind(null, props, true)}
            onMouseEnter={handlePopoverEnter.bind(null, props, false)}
            onMouseLeave={handlePopoverLeave.bind(null, props)}
          >
            <Popover.Button
              {...{
                as: buttonElement,
                className: classNames(buttonClassName),
                ref: referenceElement,
                ...buttonProps,
              }}
            >
              {children}
            </Popover.Button>
            <Transition
              as="span"
              show={!!content && props.open}
              unmount={false}
              enter="tw-transition tw-ease-out tw-duration-200"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-transition tw-ease-in tw-duration-150"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <Popover.Panel>
                <div
                  ref={setPopperElement}
                  className={classNames(
                    'tw-z-30 tw-overflow-hidden tw-rounded-lg tw-bg-brand-body tw-p-4 tw-shadow-md',
                    panelClassName
                  )}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  {content}
                </div>
              </Popover.Panel>
            </Transition>
          </span>
        )
      }}
    </Popover>
  )
}

export default PopoverHover
